import React from 'react';
import type { FC } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { Theme } from 'src/theme';

interface Props {
  dark: boolean;
}

interface LogoProps {
  [key: string]: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  logo: (props: Props) => ({
    fontSize: 20,
    fontWeight: 500,
    color: props.dark ? theme.palette.common.black : theme.palette.common.white
  })
}));

const Logo: FC<LogoProps> = (props: Props) => {
  const classes = useStyles(props);
  return (
    <Typography className={classes.logo}>
      REDIGA
    </Typography>
  );
}

export default Logo;
