import mock from 'src/utils/mock';
import type {
  Model
} from 'src/types/model';

const models: Model[] = [
  {
    id: 1,
    name: '01101010 north side',
    surface: 'Flat',
    solarPanelVoltage: 140,
    solarPanelCurrent: 30,
    sides: 'W, N, Y',
    angle: 90,
    shape: 'Square',
    batteryCapacity: 200,
    ledPower: 50,
    attachments: 'CCTV, Co2 sensors',
    systemVoltage: 24,
    ledCurrent: 10,
    chargingProhibited: true,
    overchargeVoltage: 7.5,
    overchargeRecoveryVoltage: 15.5,
    motionSensorSensitivity: 'dB',
    batteryHeating: -35,
    batteryType: 'lifepo4',
    lightControlDelay: 20,
  }
];

mock.onGet('/api/models').reply(() => [200, { models }]);

mock.onGet('/api/models/1').reply(() => [200, { model: models[0] }]);
