import moment from 'moment';
import mock from 'src/utils/mock';
import { Alert } from 'src/types/alert';

mock.onGet('/api/monitoring').reply(() => {
  const alerts: Alert[] = [
    {
      id: 0,
      type: 'LED',
      active: true,
      name: '01101010',
      attachments: [{}],
      batteryState: 20,
      batteryVoltage: 14.4,
      chargingCurrent: 2.9,
      averageYield: 286,
      yieldConsumption: 286,
      dimmingSchedule: 'October',
      location: {
        lat: 54.687157,
        lng: 25.279652
      }
    },
    {
      id: 1,
      type: 'BROKEN MODULE',
      name: '01101010',
      active: false,
      attachments: [],
      batteryState: 20,
      batteryVoltage: 14.4,
      chargingCurrent: 2.9,
      averageYield: 286,
      yieldConsumption: 286,
      dimmingSchedule: 'October',
      location: {
        lat: 54.687177,
        lng: 25.279537
      }
    },
    {
      id: 2,
      type: 'BAD',
      name: '01101010',
      active: false,
      attachments: [{}],
      batteryState: 20,
      batteryVoltage: 14.4,
      chargingCurrent: 2.9,
      averageYield: 286,
      yieldConsumption: 286,
      dimmingSchedule: 'October',
      location: {
        lat: 54.687196,
        lng: 25.279395
      }
    },
    {
      id: 3,
      type: 'VERY BAD',
      name: '01101010',
      active: false,
      attachments: [],
      batteryState: 90,
      batteryVoltage: 14.4,
      chargingCurrent: 2.9,
      averageYield: 286,
      yieldConsumption: 286,
      dimmingSchedule: 'October',
      location: {
        lat: 54.687211,
        lng: 25.279248
      }
    },
    {
      id: 4,
      type: 'BAD',
      name: '01101010',
      active: false,
      attachments: [],
      batteryState: 90,
      batteryVoltage: 14.4,
      chargingCurrent: 2.9,
      averageYield: 286,
      yieldConsumption: 286,
      dimmingSchedule: 'October',
      location: {
        lat: 54.687220,
        lng: 25.279179
      }
    },
    {
      id: 5,
      type: 'BAD',
      name: '01101010',
      active: false,
      attachments: [],
      batteryState: 90,
      batteryVoltage: 14.4,
      chargingCurrent: 2.9,
      averageYield: 286,
      yieldConsumption: 286,
      dimmingSchedule: 'October',
      location: {
        lat: 54.687241,
        lng: 25.279023
      }
    }
  ];

  return [200, { alerts }];
});

mock.onGet('/api/monitoring/1').reply(() => {
  const alert: Alert = {
    id: 1,
    type: 'BROKEN MODULE',
    name: '01101010',
    active: false,
    attachments: [],
    batteryState: 32.2,
    batteryVoltage: 11.17,
    chargingCurrent: 3.84,
    averageYield: 286,
    yieldConsumption: 286,
    dimmingSchedule: 'October',
    sensorData: [
      {
        time: moment().toDate(),
        value: 170
      },
      {
        time: moment()
          .add(1, 'hour')
          .toDate(),
        value: 120
      },
      {
        time: moment()
          .add(2, 'hour')
          .toDate(),
        value: 180
      },
      {
        time: moment()
          .add(3, 'hour')
          .toDate(),
        value: 110
      },
      {
        time: moment()
          .add(4, 'hour')
          .toDate(),
        value: 180
      },
      {
        time: moment()
          .add(5, 'hour')
          .toDate(),
        value: 120
      },
      {
        time: moment()
          .add(6, 'hour')
          .toDate(),
        value: 220
      },
      {
        time: moment()
          .add(7, 'hour')
          .toDate(),
        value: 120
      },
      {
        time: moment()
          .add(8, 'hour')
          .toDate(),
        value: 170
      },
      {
        time: moment()
          .add(9, 'hour')
          .toDate(),
        value: 120
      }
    ],
    yieldData: {
      generated: [
        {
          time: moment().set({hour: 7, minute: 0}).toDate(),
          value: 0
        },
        {
          time: moment().set({hour: 7, minute: 30}).toDate(),
          value: 6.53
        },
        {
          time: moment().set({hour: 8, minute: 0}).toDate(),
          value: 12.33
        },
        {
          time: moment().set({hour: 8, minute: 30}).toDate(),
          value: 18.85
        },
        {
          time: moment().set({hour: 9, minute: 0}).toDate(),
          value: 24
        },
        {
          time: moment().set({hour: 9, minute: 30}).toDate(),
          value: 28.94
        },
        {
          time: moment().set({hour: 10, minute: 0}).toDate(),
          value: 32.6
        },
        {
          time: moment().set({hour: 10, minute: 30}).toDate(),
          value: 36.17
        },
        {
          time: moment().set({hour: 11, minute: 0}).toDate(),
          value: 38.51
        },
        {
          time: moment().set({hour: 11, minute: 30}).toDate(),
          value: 39.75
        },
        {
          time: moment().set({hour: 12, minute: 0}).toDate(),
          value: 40.53
        },
         {
          time: moment().set({hour: 12, minute: 30}).toDate(),
          value: 39.75
        },
        {
          time: moment().set({hour: 13, minute: 0}).toDate(),
          value: 38.51
        },
         {
          time: moment().set({hour: 13, minute: 30}).toDate(),
          value: 36.17
        },
        {
          time: moment().set({hour: 14, minute: 0}).toDate(),
          value: 32.60
        },
        {
          time: moment().set({hour: 14, minute: 30}).toDate(),
          value: 28.94
        },
        {
          time: moment().set({hour: 15, minute: 0}).toDate(),
          value: 24.00
        },
        {
          time: moment().set({hour: 15, minute: 30}).toDate(),
          value: 18.85
        },
        {
          time: moment().set({hour: 16, minute: 0}).toDate(),
          value: 12.33
        },
        {
          time: moment().set({hour: 16, minute: 30}).toDate(),
          value: 6.53
        },
        {
          time: moment().set({hour: 17, minute: 0}).toDate(),
          value: 0
        }
      ],
      consumed: [
        {
          time: moment().set({hour: 7, minute: 0}).toDate(),
          value: 1.467
        },
        {
          time: moment().set({hour: 7, minute: 30}).toDate(),
          value: 1.467
        },
        {
          time: moment().set({hour: 8, minute: 0}).toDate(),
          value: 1.467
        },
        {
          time: moment().set({hour: 8, minute: 30}).toDate(),
          value: 1.467
        },
        {
          time: moment().set({hour: 9, minute: 0}).toDate(),
          value: 1.467
        },
        {
          time: moment().set({hour: 9, minute: 30}).toDate(),
          value: 1.467
        },
        {
          time: moment().set({hour: 10, minute: 0}).toDate(),
          value: 1.467
        },
        {
          time: moment().set({hour: 10, minute: 30}).toDate(),
          value: 1.467
        },
        {
          time: moment().set({hour: 11, minute: 0}).toDate(),
          value: 1.467
        },
        {
          time: moment().set({hour: 11, minute: 30}).toDate(),
          value: 1.467
        },
        {
          time: moment().set({hour: 12, minute: 0}).toDate(),
          value: 1.467
        },
         {
          time: moment().set({hour: 12, minute: 30}).toDate(),
          value: 1.467
        },
        {
          time: moment().set({hour: 13, minute: 0}).toDate(),
          value: 1.467
        },
         {
          time: moment().set({hour: 13, minute: 30}).toDate(),
          value: 1.467
        },
        {
          time: moment().set({hour: 14, minute: 0}).toDate(),
          value: 1.467
        },
        {
          time: moment().set({hour: 14, minute: 30}).toDate(),
          value: 1.467
        },
        {
          time: moment().set({hour: 15, minute: 0}).toDate(),
          value: 1.467
        },
        {
          time: moment().set({hour: 15, minute: 30}).toDate(),
          value: 1.467
        },
        {
          time: moment().set({hour: 16, minute: 0}).toDate(),
          value: 1.467
        },
        {
          time: moment().set({hour: 16, minute: 30}).toDate(),
          value: 1.467
        },
        {
          time: moment().set({hour: 17, minute: 0}).toDate(),
          value: 1.467
        }
      ],
    },
    batteryData: [
        {
          time: moment().set({hour: 7, minute: 0}).toDate(),
          value: 10
        },
        {
          time: moment().set({hour: 7, minute: 30}).toDate(),
          value: 10.07
        },
        {
          time: moment().set({hour: 8, minute: 0}).toDate(),
          value: 10.15
        },
        {
          time: moment().set({hour: 8, minute: 30}).toDate(),
          value: 10.29
        },
        {
          time: moment().set({hour: 9, minute: 0}).toDate(),
          value: 10.48
        },
        {
          time: moment().set({hour: 9, minute: 30}).toDate(),
          value: 10.72
        },
        {
          time: moment().set({hour: 10, minute: 0}).toDate(),
          value: 10.99
        },
        {
          time: moment().set({hour: 10, minute: 30}).toDate(),
          value: 11.29
        },
        {
          time: moment().set({hour: 11, minute: 0}).toDate(),
          value: 11.61
        },
        {
          time: moment().set({hour: 11, minute: 30}).toDate(),
          value: 11.96
        },
        {
          time: moment().set({hour: 12, minute: 0}).toDate(),
          value: 12.31
        },
         {
          time: moment().set({hour: 12, minute: 30}).toDate(),
          value: 12.65
        },
        {
          time: moment().set({hour: 13, minute: 0}).toDate(),
          value: 13
        },
         {
          time: moment().set({hour: 13, minute: 30}).toDate(),
          value: 13.32
        },
        {
          time: moment().set({hour: 14, minute: 0}).toDate(),
          value: 13.62
        },
        {
          time: moment().set({hour: 14, minute: 30}).toDate(),
          value: 13.89
        },
        {
          time: moment().set({hour: 15, minute: 0}).toDate(),
          value: 14.11
        },
        {
          time: moment().set({hour: 15, minute: 30}).toDate(),
          value: 14.3
        },
        {
          time: moment().set({hour: 16, minute: 0}).toDate(),
          value: 14.43
        },
        {
          time: moment().set({hour: 16, minute: 30}).toDate(),
          value: 14.51
        },
        {
          time: moment().set({hour: 17, minute: 0}).toDate(),
          value: 14.53
        }
      ]
  };

  return [200, { alert }];
});
