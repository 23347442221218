import mock from 'src/utils/mock';
import type {
  DimmingStats,
  DimmingData
} from 'src/types/dimming';

const dimmingList: DimmingData[] = [
  {
    id: 1,
    name: 'October',
    data: <DimmingStats[]>[
      {
        time: 1,
        percent: 100
      },
      {
        time: 2,
        percent: 60
      },
      {
        time: 3,
        percent: 60
      },
      {
        time: 4,
        percent: 100
      },
      {
        time: 5,
        percent: 20
      },
      {
        time: 6,
        percent: 60
      },
      {
        time: 7,
        percent: 0
      },
      {
        time: 8,
        percent: 0
      }
    ]
  }
];

mock.onGet('/api/dimming').reply(() => {
  return [200, { dimmingData: dimmingList }];
});

mock.onGet('/api/dimming/1').reply(() => {
  const singleDimming: DimmingData = dimmingList[0];
  return [200, { singleDimming }];
});
